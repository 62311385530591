import React from "react";
import "./Components.css";

export default function Alert() {

return (
    <>
        <div className="alert">
           Username or password is incorrect. Please try again.  
        </div>
    </>
);
}
