import React, { useState, useRef } from 'react';
import { API_BASE_URL } from '../../config';
import axios from 'axios'; 
import Alert from '../Alert'; 
import './Form.css';

interface LoginResponse {
  token: string;
}

export default function Form(): JSX.Element {
  const [display, setDisplay] = useState<boolean>(false);

  const usernameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    const username = usernameRef.current?.value;
    const password = passwordRef.current?.value;

    if (!username || !password) {
      console.error('Username or password is missing');
      return;
    }

    axios.get<LoginResponse>(`${API_BASE_URL}/login?username=${username}&password=${password}`)
      .then((response) => {
        console.log(response);
        console.log("Token is: " + response.data.token);
        localStorage.setItem("token", JSON.stringify(response.data.token));
        if (response.status === 200) {
          console.log("Status code is 200.");
          window.location.reload();
        } else {
          console.log("Status code is not 200.");
        }
      })
      .catch((error) => {
        console.error(error);
        setDisplay(true);
        setTimeout(() => {
          setDisplay(false);
        }, 5000);
      });
  };

  return (
    <>
      <form>
        <input
          type="text"
          id="username"
          name="username"
          placeholder="Enter your username"
          ref={usernameRef}
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Enter your password"
          ref={passwordRef}
        />
        <button type="button" onClick={handleClick}>
          Login
        </button>
      </form>
      {display && <Alert />}
    </>
  );
}
