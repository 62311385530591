import React from "react";
import "./Camera.css";

interface CameraProps {
    alt: string;
    src: string;
}

export default function Camera({ alt, src }: CameraProps) {

return (
    <>
        <div>
        <img
            alt={alt}
            src={src}
        />
        </div>
    </>
);
}
